import Fade from "react-reveal";
import * as S from "./style";

export const BenefitsComponent = (props) => {
  const {
    isEnglish,
    img,
    label,
    description,
    imgPosition,
    engLabel,
    engDescription,
  } = props;

  return (
    <S.Wrapper>
      {imgPosition === "left" ? (
        <S.MainBlock>
          <Fade bottom>
            <S.WalletsImg src={img} alt="#" />
          </Fade>
          <Fade bottom>
            {isEnglish ? (
              <S.LabelsWrapper>
                <S.Label>{engLabel}</S.Label>
                <S.Text>{engDescription}</S.Text>
              </S.LabelsWrapper>
            ) : (
              <S.LabelsWrapper>
                <S.Label>{label}</S.Label>
                <S.Text>{description}</S.Text>
              </S.LabelsWrapper>
            )}
          </Fade>
        </S.MainBlock>
      ) : (
        <S.MainBlock>
          <Fade top>
            {isEnglish ? (
              <S.LabelsWrapper>
                <S.Label>{engLabel}</S.Label>
                <S.Text>{engDescription}</S.Text>
              </S.LabelsWrapper>
            ) : (
              <S.LabelsWrapper>
                <S.Label>{label}</S.Label>
                <S.Text>{description}</S.Text>
              </S.LabelsWrapper>
            )}
          </Fade>
          <Fade top>
            <S.WalletsImg src={img} alt="#" />
          </Fade>
        </S.MainBlock>
      )}
    </S.Wrapper>
  );
};
