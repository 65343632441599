import { useWindowDimensions } from "../../../hooks/useWindowDimensions";
import Tilt from "../../../logic/Tilt";
import Fade from "react-reveal";
import { Wrapper, Link, Img, Description } from "./style";

const DevBlock = (props) => {
  const { name, avatar, link, children } = props;

  const { width } = useWindowDimensions();

  return (
    <Wrapper>
      <Fade bottom>
        <Link href={link} target="_blank" rel="noreferrer">
          {name}
        </Link>
      </Fade>
      <Fade bottom>
        {width > 768 ? (
          <Tilt
            children={
              <a href={link} target="_blank" rel="noreferrer">
                <Img src={avatar} alt={`${name} avatar`} />
              </a>
            }
          />
        ) : (
          <a href={link} target="_blank" rel="noreferrer">
            <Img src={avatar} alt={`${name} avatar`} />
          </a>
        )}
      </Fade>
      <Fade bottom>
        <Description>{children}</Description>
      </Fade>
    </Wrapper>
  );
};

export default DevBlock;
