import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 20px;
  text-align: center;

  @media screen and (max-width: 767px) {
    margin-bottom: 40px;
  }
`;

export const Link = styled.a`
  font-weight: 900;
  font-size: 24px;
  line-height: 28px;
  text-decoration: none;
  color: #000;
`;

export const Img = styled.img`
  width: 200px;
  height: 200px;
  margin: 25px 0px;
  transform: perspective(1000px);

  @media screen and (max-width: 767px) {
    width: 150px;
    height: 150px;
  }
`;

export const Description = styled.span`
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;

  span {
    font-weight: 900;
  }

  @media screen and (max-width: 767px) {
    font-size: 16px;
  }
`;
