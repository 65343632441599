import { Item } from "./Item";
import { Wrapper, Title, Line, ItemsWrapper } from "./style";

const APP_LINK = "https://app.toncells.org/";

const RoadMap = (props) => {
  const { isEnglish } = props;

  return (
    <Wrapper>
      <Title>ROADMAP</Title>
      <Line />

      {isEnglish ? (
        <ItemsWrapper>
          <Item title="Q1">
            - Landing
            <br /> - Marketing
            <br /> - Community Development
          </Item>
          <Item title="Q2">
            - Completion of work on the beta{" "}
            <a href={APP_LINK} target="_blank" rel="noreferrer">
              web-app
            </a>
            <br /> - Start of sales of the first wave according to the white
            list
            <br /> - Development of NFT editing logic
          </Item>
          <Item title="Q3">
            - Start of open sales of the first wave <br />
            - NFT editing logic integration
            <br /> - Support for popular ton wallets
            <br /> - Start of sales of the entire field according to the white
            list
          </Item>
          <Item title="Q4">
            - Start of open sales of the entire field
            <br /> - Toncells based metaverse development
            <br /> - Implementation of staking-opportunities
          </Item>
        </ItemsWrapper>
      ) : (
        <ItemsWrapper>
          <Item title="Q1">
            - Лендинг
            <br /> - Маркетинг
            <br /> - Развитие коммьюнити
          </Item>
          <Item title="Q2">
            - Завершение работы над бетой{" "}
            <a href={APP_LINK} target="_blank" rel="noreferrer">
              web-app
            </a>
            <br /> - Старт продаж первой волны по вайт листу
            <br /> - Разработка логики редактирования NFT
          </Item>
          <Item title="Q3">
            - Старт открытых продаж первой волны <br />
            - Интеграция логики редактирования NFT
            <br /> - Поддержка популярных в сети кошельков
            <br /> - Старт продаж всего поля по вайт листу
          </Item>
          <Item title="Q4">
            - Старт открытых продаж всего поля
            <br /> - Разработка metaverse на основе поля
            <br /> - Внедрение staking - возможностей
          </Item>
        </ItemsWrapper>
      )}
    </Wrapper>
  );
};

export default RoadMap;
