import styled from "styled-components";

export const Wrapper = styled.div`
  border-radius: 15px;
  padding: 24px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.3);
  transition: 0.3s;

  &:hover {
    transform: scale(1.25);
  }

  @media screen and (max-width: 767px) {
    width: 100%;

    &:hover {
      transform: scale(1);
    }
  }
`;

export const Title = styled.h6`
  margin: 0;
  padding: 0;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: rgba(51, 51, 51, 0.39);
`;

export const Text = styled.div`
  font-weight: 400;
  font-size: 21px;
  line-height: 28px;
  color: #000;

  @media screen and (max-width: 767px) {
    font-size: 17px;
  }
`;
