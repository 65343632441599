import * as S from "./style";

export const Item = (props) => {
  const { title, children } = props;

  return (
    <S.Wrapper>
      <S.Title>{title}</S.Title>
      <S.Text>{children}</S.Text>
    </S.Wrapper>
  );
};
