import { useState, useCallback } from "react";
import { WALLETS_IMG, PURCHASE_IMG, EDITING_IMG } from "./constants/images";
import Container from "./components/Container";
import FirstSection from "./components/FirstSection";
import AboutSection from "./components/AboutSection";
import { BenefitsComponent } from "./components/BenefitsComponent";
import RoadMap from "./components/RoadMap";
import DevSection from "./components/DevSection";
import Footer from "./components/Footer";

const benefits = [
  {
    label: "АВТОРИЗАЦИЯ",
    description:
      "Подключайте любой удобный для вас кошелек сети TON для взаимодействия с вашими NFT.",
    engLabel: "AUTHORIZATION",
    engDescription:
      "Connect any wallet of the TON network convenient for you to interact with your NFTs.",
    img: WALLETS_IMG,
    imgPosition: "left",
  },
  {
    label: "ПОКУПКА",
    description:
      "Выбирайте и оплачивайте нужные вам ячейки, не покидая TON Cells. Наши разработчики реализовали решение оплаты прямо в нашем web-приложении, помимо удобства, это позволяет не платить лишнюю комиссию маркетплейсам",
    engLabel: "PURCHASE",
    engDescription:
      "Choose and pay for the cells you need, without leaving TON Cells. Our developers have implemented a payment solution directly in our web application, in addition to convenience, this allows you not to pay an extra commission to marketplaces",
    img: PURCHASE_IMG,
    imgPosition: "right",
  },
  {
    label: "РЕДАКТИРОВАНИЕ",
    description:
      "Редактируйте купленные вами NFT, с помощью предусмотренного для этого функционала. Закрашивайте клетки с помощью удобной палитры выбора цветов. Загружайте собственные картинки, а также редактируйте описание ячеек, добавляя туда ссылки на ваши ресурсы и описание.",
    engLabel: "EDITING",
    engDescription:
      "Edit the NFTs you bought using the functionality provided for this. Color the cells with a handy color picker. Upload your own pictures, as well as edit the description of the cells, adding links to your resources and description there.",
    img: EDITING_IMG,
    imgPosition: "left",
  },
];

const App = () => {
  const [isEnglish, setIsEnglish] = useState(false);

  const handleRuBtnClick = useCallback(() => setIsEnglish(false), []);
  const handleEngBtnClick = useCallback(() => setIsEnglish(true), []);

  return (
    <>
      <Container>
        <FirstSection
          isEnglish={isEnglish}
          handleRuBtnClick={handleRuBtnClick}
          handleEngBtnClick={handleEngBtnClick}
        />
        <AboutSection isEnglish={isEnglish} />
        {benefits.map((benefit) => {
          const {
            label,
            description,
            engLabel,
            engDescription,
            img,
            imgPosition,
          } = benefit;

          return (
            <BenefitsComponent
              isEnglish={isEnglish}
              label={label}
              description={description}
              engLabel={engLabel}
              engDescription={engDescription}
              img={img}
              imgPosition={imgPosition}
            />
          );
        })}
        <RoadMap isEnglish={isEnglish} />
        <DevSection isEnglish={isEnglish} />
      </Container>

      <Footer isEnglish={isEnglish} />
    </>
  );
};

export default App;
