import Container from "../Container";
import {
  TG_ICON,
  TWITTER_ICON,
  FOOTER_LIZARD_IMG,
} from "../../constants/images";
import {
  Wrapper,
  Title,
  Line,
  LinkWrapper,
  Link,
  TwitterIcon,
  TgIcon,
  LizardImg,
  FlexWrapper,
} from "./style";

const Footer = () => {
  return (
    <Wrapper>
      <Container>
        <FlexWrapper>
          <LizardImg src={FOOTER_LIZARD_IMG} />
          <Title>LINKS</Title>
          <Line />
          <TgIcon src={TG_ICON} alt="Telegram Logo" />
          <LinkWrapper>
            <Link href="https://t.me/toncells" target="_blank" rel="noreferrer">
              @toncells
            </Link>
            <Link
              href="https://t.me/toncellseng"
              target="_blank"
              rel="noreferrer"
            >
              @toncellseng
            </Link>
          </LinkWrapper>
          <TwitterIcon src={TWITTER_ICON} alt="" />
          <Link
            href="https://twitter.com/toncells"
            target="_blank"
            rel="noreferrer"
          >
            @toncells
          </Link>
        </FlexWrapper>
      </Container>
    </Wrapper>
  );
};

export default Footer;
