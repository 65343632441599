export const TON_LOGO = "/images/tonlogo.png";
export const TG_ICON = "/images/telegramIcon.png";
export const TWITTER_ICON = "/images/twitterIcon.png";
export const SEPEZHO_IMG = "/images/sepezho.png";
export const FIEZZY_IMG = "/images/fiezzy.png";
export const SEMEN_IMG = "/images/semen.png";
export const SVYAT_IMG = "/images/svyat.png";
export const ABOUT_IMG = "/images/about.png";
export const LIZARD_IMG = "/images/lizard.png";
export const FOOTER_LIZARD_IMG = "/images/footerLizard.png";
export const WALLETS_IMG = "/images/wallets.svg";
export const PURCHASE_IMG = "/images/purschase.svg";
export const EDITING_IMG = "/images/editing.svg";
