import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100vh;
  scroll-snap-align: start;

  @media screen and (max-width: 767px) {
    flex-direction: column;
    height: auto;
    gap: 50px;
    margin-bottom: 15vh;
  }
`;

export const InfoBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 400px;

  // @media screen and (max-width: 1200px) {
  //   width: 70%;
  // }

  @media screen and (max-width: 767px) {
    width: 100%;
  }
`;

export const Title = styled.span`
  font-size: 36px;
  line-height: 42px;
  letter-spacing: 0.18em;
  font-weight: 100;
  letter-spacing: 0.205em;
`;

export const Line = styled.div`
  height: 1px;
  width: 80%;
  background-color: #000;
  margin: 20px 0px;

  @media screen and (max-width: 767px) {
    width: 45%;
  }
`;

export const Info = styled.span`
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
  text-align: justify;

  font-weight: 300;
  font-size: 16px;
  line-height: 19px;

  @media screen and (max-width: 767px) {
    font-size: 16px;
  }

  strong {
    margin: 0;
    font-weight: 500;
  }
`;

export const Img = styled.img`
  width: 300px;
  height: 300px;

  // box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.3);
  border-radius: 32px;
  font-weight: 100;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: perspective(500px);
  overflow: hidden;
`;

export const Link = styled.a`
  text-decoration: none;
  color: #000;
  font-weight: 400;
`;
