import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
`;

export const LangLine = styled.div`
  height: 10px;
  width: 1px;
  background-color: #000;
`;

export const LangBtn = styled.button`
  border: none;
  outline: none;
  color: #000;
  background: none;
  padding: 10px;
  cursor: pointer;

  border-bottom: ${({ active }) => (active ? `1px solid #000` : "none")};
`;
