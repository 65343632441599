import { LIZARD_IMG } from "../../constants/images";
import Tilt from "../../logic/Tilt";
import { useWindowDimensions } from "../../hooks/useWindowDimensions";
import Fade from "react-reveal";
import { Wrapper, InfoBlock, Title, Line, Info, Img } from "./style";

const AboutSection = (props) => {
  const { isEnglish } = props;

  const { width } = useWindowDimensions();

  return (
    <Wrapper>
      {isEnglish ? (
        <Fade left>
          <InfoBlock>
            <Title>About</Title>
            <Line />
            <Info>
              Hey! We're a team consisting of 7 people and we're creating really
              incredible project called TonCells! What makes us unique is a
              100x100 celled field where NFTs are located and these NFTs are
              editable! You can customize them in any way you want: change
              description, draw, insert a picture, video or whatever you want!
              If you own multiple NFTs you can edit them all simultaneously!
              <br />
              <br />
              Talking about technologies, we don't use centralized backend for
              storing data about NFT, all of the information is stored in NFT
              itself, it was made for optimization.
              <br />
              <br />
              We're also planning to create a metaverse project with integration
              NFT based on TON blockchain. Watch us!
            </Info>
          </InfoBlock>
        </Fade>
      ) : (
        <Fade left>
          <InfoBlock>
            <Title>About</Title>
            <Line />
            <Info>
              TonCells - это большое поле состоящее из 10.000 участков, каждый
              из которых - редактируемая NFT.
              <br />
              <br />
              Благодаря обновленному функционалу нашего web-приложения, вы
              можете рисовать, в ваших ячейках, добавлять картинки, оставлять
              ссылки на ваши контакты / проекты / сервисы, а также добавлять
              описание к каждой из ячеек!
              <br />
              <br />А самое интересное что это еще не конец! Текущее поле
              TonCells, служит фундаментом и по совместительству мостом для
              разворачивания полномасштабной <strong>Metaverse</strong> - игры !
            </Info>
          </InfoBlock>
        </Fade>
      )}
      {width > 768 ? (
        <Fade right>
          <Tilt children={<Img alt="about" src={LIZARD_IMG} />} />
        </Fade>
      ) : (
        <Fade right>
          <Img alt="about" src={LIZARD_IMG} />
        </Fade>
      )}
    </Wrapper>
  );
};

export default AboutSection;
