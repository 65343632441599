import * as S from "./style";

const LangBlock = (props) => {
  const { isEnglish, handleEngBtnClick, handleRuBtnClick } = props;

  return (
    <S.Wrapper>
      <S.LangBtn active={!isEnglish} onClick={handleRuBtnClick}>
        RU
      </S.LangBtn>
      <S.LangLine />
      <S.LangBtn active={isEnglish} onClick={handleEngBtnClick}>
        ENG
      </S.LangBtn>
    </S.Wrapper>
  );
};

export default LangBlock;
