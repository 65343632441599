import Fade from "react-reveal";
import {
  SEPEZHO_IMG,
  FIEZZY_IMG,
  SEMEN_IMG,
  SVYAT_IMG,
} from "../../constants/images";
import DevBlock from "./DevBlock";
import { Wrapper, Title, Line, DevWrapper } from "./style";

const LINKS = {
  sepezho: "https://t.me/sepezho",
  fiezzy: "https://t.me/fiezzyy",
  semen: "https://t.me/fulmiPM",
  svyat: "https://t.me/svyatikpigeon",
};

const Developers = (props) => {
  const { isEnglish } = props;

  return (
    <Wrapper>
      <Fade top>
        <Title>DEVELOPERS</Title>
      </Fade>

      <Line />

      {isEnglish ? (
        <>
          <DevWrapper>
            <DevBlock name="@sepezho" avatar={SEPEZHO_IMG} link={LINKS.sepezho}>
              <span>Founder</span>. Middle fullstack dev,
              <br />
              works in Web3.
            </DevBlock>
            <DevBlock name="@fiezzyy" avatar={FIEZZY_IMG} link={LINKS.fiezzy}>
              <span>CTO</span>. Middle frontend dev,
              <br /> works in WEB3.
            </DevBlock>
          </DevWrapper>
          <DevWrapper>
            <DevBlock name="@fulmiPM" avatar={SEMEN_IMG} link={LINKS.semen}>
              <span>PM</span>. Project Manager.
              <br /> Developing in the field of management
              <br /> help develop projects.
            </DevBlock>
            <DevBlock
              name="@svyatikpigeon"
              avatar={SVYAT_IMG}
              link={LINKS.svyat}
            >
              <span>Artist</span>.
              <br />I am into digital illustration.
            </DevBlock>
          </DevWrapper>
        </>
      ) : (
        <>
          <DevWrapper>
            <DevBlock name="@sepezho" avatar={SEPEZHO_IMG} link={LINKS.sepezho}>
              <span>Founder</span>. Mid front-end dev,
              <br /> которого потянуло в мир Web3.
            </DevBlock>
            <DevBlock name="@fiezzyy" avatar={FIEZZY_IMG} link={LINKS.fiezzy}>
              <span>CTO</span>. Mid front-end dev.
              <br />
              Работаю в секторе Web3.
              <br />
              Увлекаюсь блокчейном и NFT.
              <br />
            </DevBlock>
          </DevWrapper>
          <DevWrapper>
            <DevBlock name="@fulmiPM" avatar={SEMEN_IMG} link={LINKS.semen}>
              <span>PM</span>. Project Manager.
              <br /> Развиваюсь в сфере менеджмента, <br />
              помогаю развитию проектов.
            </DevBlock>
            <DevBlock
              name="@svyatikpigeon"
              avatar={SVYAT_IMG}
              link={LINKS.svyat}
            >
              <span>Artist</span>. Художник.
              <br />
              Увлекаюсь digital-иллюстрацией.
            </DevBlock>
          </DevWrapper>
        </>
      )}
    </Wrapper>
  );
};

export default Developers;
