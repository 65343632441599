import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
  scroll-snap-align: start;
  margin-bottom: 30vh;

  @media screen and (max-width: 767px) {
    height: auto;
    // margin-bottom: 600px;
  }
`;

export const Title = styled.span`
  font-size: 36px;
  line-height: 42px;
  letter-spacing: 0.18em;
  font-weight: 100;
  letter-spacing: 0.205em;
`;

export const Line = styled.div`
  width: 35%;
  height: 1px;
  background-color: #000;
  margin: 30px 0px 120px 0px;
`;

export const RoadMapCase = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;

  @media screen and (max-width: 767px) {
    gap: 28px;
  }
`;

export const RoadMapItem = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
`;

export const Circle = styled.div`
  min-width: 12px;
  height: 12px;
  border-radius: 50px;
  background-color: #000;
  display: flex;
  justify-content: center;
  position: relative;
`;

export const CircleLine = styled.div`
  position: absolute;
  width: 2px;
  background-color: #000;
  height: 10%;
  margin: 0 -5px 0 5px;

  @media screen and (max-width: 400px) {
    height: 15%;
  }
`;

export const Label = styled.span`
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;

  @media screen and (max-width: 767px) {
    font-size: 16px;
  }
`;

export const ItemsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  gap: 100px;
`;
