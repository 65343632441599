import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  aligm-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;

  @media screen and (max-width: 767px) {
    height: auto;
    margin: 250px 0;
  }
`;

export const MainBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  @media screen and (max-width: 1024px) {
    flex-direction: column;
    justify-content: center;
    gap: 80px;
  }
`;

export const WalletsImg = styled.img`
  width: 400px;
`;

export const LabelsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  max-width: 420px;
`;

export const Label = styled.div`
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: rgba(51, 51, 51, 0.39);
`;

export const Text = styled.div`
  font-weight: 400;
  font-size: 21px;
  line-height: 28px;
  color: #000;

  @media screen and (max-width: 767px) {
    font-size: 17px;
  }
`;
