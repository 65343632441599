import styled from "styled-components";

export const Wrapper = styled.div`
  max-width: 1024px;
  width: 100%;
  margin: 0 auto;
  padding: 0px 50px;

  @media screen and (max-width: 1024px) {
    width: 90%;
    padding: 0;
  }
`;
