import styled from "styled-components";

export const TopBlock = styled.div`
  position: absolute;
  top: 20px;
`;

export const TonBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 8px;
  box-shadow: 0px 0px 28px rgba(0, 0, 0, 0.18);
  border-radius: 34px;
  width: 108px;
  margin: 20px auto 0px auto;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 20px;
`;

export const Img = styled.img`
  width: 24px;
  height: 24px;
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
  position: relative;
  gap: 20px;
  scroll-snap-align: start;
`;

export const Title = styled.span`
  font-weight: 100;
  font-size: 70px;
  line-height: 82px;
  letter-spacing: 0.205em;

  @media screen and (max-width: 767px) {
    font-size: 50px;
  }

  @media screen and (max-width: 767px) {
    font-size: 45px;
  }
`;

export const Line = styled.div`
  height: 1px;
  width: 35%;
  background-color: #000;
  margin: 15px 0px;

  @media screen and (max-width: 767px) {
    margin: 5px 0px 15px 0px;
  }
`;

export const Description = styled.span`
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  text-align: center;

  @media screen and (max-width: 767px) {
    font-size: 16px;
  }
`;

export const LaunchBtn = styled.button`
  border: 1px dashed #333;
  outline: none;
  background: none;
  border-radius: 5px;
  color: #333;
  cursor: pointer;
  transition: 0.3s;
  padding: 20px 45px;

  &:hover {
    background-color: #333;
    color: #fff;
  }
`;
