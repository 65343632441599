import styled from "styled-components";

export const Wrapper = styled.div`
	padding: 50px 0px;
	background-color: #000;
	margin-top: 250px;
`;

export const FlexWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	position: relative;
`;

export const TgIcon = styled.img`
	width: 80px;
	height: 80px;
	margin: 20px 0px;

	@media screen and (max-width: 767px) {
		width: 60px;
		height: 60px;
	}
`;

export const Title = styled.div`
	color: #fff;
	font-size: 36px;
	line-height: 42px;
	letter-spacing: 0.18em;
	font-weight: 100;
	letter-spacing: 0.205em;
	margin-bottom: 35px;
`;

export const Line = styled.div`
	width: 15%;
	height: 1px;
	background-color: #fff;
`;

export const LinkWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 15px;
	margin-bottom: 40px;

	@media screen and (max-width: 767px) {
		gap: 5px;
	}
`;

export const Link = styled.a`
	font-weight: 100;
	font-size: 24px;
	line-height: 28px;
	text-decoration: none;
	color: #fff;

	@media screen and (max-width: 767px) {
		font-size: 18px;
	}
`;

export const TwitterIcon = styled.img`
	margin-bottom: 25px;
	width: 63px;
	height: 52px;

	@media screen and (max-width: 767px) {
		width: 43px;
		height: 32px;
	}
`;

export const LizardImg = styled.img`
	width: 200px;
	height: 200px;
	transform: rotate(180deg);
	position: absolute;
	top: -122px;
	left: 70px;

	@media screen and (max-width: 767px) {
		left: 0;
		top: -82px;
		width: 90px;
		height: 90px;
	}
`;
