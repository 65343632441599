import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
  scroll-snap-align: start;

  @media screen and (max-width: 767px) {
    height: auto;
  }
`;

export const Title = styled.span`
  font-size: 36px;
  line-height: 42px;
  letter-spacing: 0.18em;
  font-weight: 100;
  letter-spacing: 0.205em;
`;

export const Line = styled.div`
  width: 35%;
  height: 1px;
  background-color: #000;
  margin: 30px 0px 80px 0px;
`;

export const DevWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  &:last-child {
    margin-top: 50px;
    justify-content: center;
    gap: 100px;
  }

  @media screen and (max-width: 767px) {
    flex-direction: column;
    justify-content: center;
  }
`;
