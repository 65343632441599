import { TON_LOGO } from "../../constants/images";
import LangBlock from "./LangBlock";
import { Fade } from "react-reveal";
import {
  TopBlock,
  TonBlock,
  Img,
  Wrapper,
  Title,
  Line,
  Description,
  LaunchBtn,
} from "./style";

const FirstSection = (props) => {
  const { isEnglish, handleRuBtnClick, handleEngBtnClick } = props;

  return (
    <Fade>
      <Wrapper>
        <TopBlock>
          <TonBlock>
            TON <Img src={TON_LOGO} alt="#" />
          </TonBlock>
          <LangBlock
            isEnglish={isEnglish}
            handleEngBtnClick={handleEngBtnClick}
            handleRuBtnClick={handleRuBtnClick}
          />
        </TopBlock>
        <Title>TONCELLS</Title>
        <Line />
        {isEnglish ? (
          <Description>
            First editable NFT collection
            <br /> based on TON blockchain.
          </Description>
        ) : (
          <Description>
            Первая в своем роде
            <br /> коллекция с редактируемыми NFT
            <br />
            на платформе TON.
          </Description>
        )}
        <a href="https://app.toncells.org/" target="_blank" rel="noreferrer">
          <LaunchBtn>
            {isEnglish ? "Launch App" : "Запустить приложение"}
          </LaunchBtn>
        </a>
      </Wrapper>
    </Fade>
  );
};

export default FirstSection;
